import { ProdutoTipo } from "Infrastructure/repositories/api/PedidoMeep";
import { useCardReader } from "application/contexts/cardReader/CardReaderContext";
import { useCatalog } from "application/contexts/catalog/CatalogContext";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { useUi } from "application/contexts/ui/UIContext";
import { moneyToFloat } from "corss-cutting/masks/money";
import { IFullCardData } from "domains/cashless/aggregates/ICardCashless";
import {
  OfflineRechargeStatus,
  Order,
} from "domains/order/agreggates/order/Order";
import { Payment } from "domains/order/agreggates/payment/payment";
import { TransactionType } from "domains/order/agreggates/payment/transactionType";
import localforage from "localforage";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import { OfflineRechargeSteps } from "./OfflineRecharge.types";
import { OrderRepositoryLocalStorage } from "Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage";
import { CefSharpCardReader } from "Infrastructure/services/cefSharpService/local/CefSharpCardReader";
import { PrintService } from "domains/order/services/printService";
import { usePrinter } from "application/contexts/printer/PrinterContext";
import { IIdleTimeHandler } from "components/idleTime/IdleTime";
import { IOrderProviderRef } from "modules/order/presentation/OrderProvider";
import {
  IOfflineRechargeStatus,
  IOrderLog,
} from "modules/order/domain/models/IOrderLog";
import { IPedidoPosDTO } from "modules/order/domain/models/IPedidoPosDTO";
import { IEmitedNfce } from "modules/order/domain/models/INfce";
import { GetError } from "utils/GetError";
import { ErrorCodeType } from "application/models/IError";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";
import { CashlessRepository } from "Infrastructure/repositories/api/CashlessRepository";
import { MeepOrderRepository } from "Infrastructure/repositories/api/OrderRepository";

const printService = PrintService();
const cefSharpCardReader = CefSharpCardReader();
const orderRepositoryDB = OrderRepositoryLocalStorage();
const cashlessRepository = CashlessRepository();
const syncOrderRepository = MeepOrderRepository();

const UseOfflineRecharge = (
  orderProvider: React.RefObject<IOrderProviderRef>,
  idleTimeRef: React.RefObject<IIdleTimeHandler>
) => {
  const { kiosk } = useKiosk();
  const {
    offlineCardData,
    clearCardData,
    enableSensor,
    readErrorMessage,
    outOfDateError,
    cardDetected,
  } = useCardReader();
  const { toastFullscreen, toast } = useUi();
  const { getConfigurationProduct } = useCatalog();
  const { replace } = useHistory();
  const { printOfflineRecharge } = usePrinter();
  const { addLog } = useAppInsights();

  const navigation = useHistory();

  const [step, setStep] = useState<OfflineRechargeSteps>(
    OfflineRechargeSteps.READ
  );
  const [rechargeValue, setRechargeValue] = useState("0.00");
  const [rechargeORder, setRechargeORder] = useState<Order | null>(null);
  const [activeLoad, setActiveLoad] = useState<"reading" | "writing" | null>(
    null
  );
  const [blockReturn, setBlockReturn] = useState(false);

  const rechargeStarted = useRef(false);
  const firstLoad = useRef(true);
  const cardToRecharge = useRef<IFullCardData | null>(null);

  const resetIdleTime = useCallback(() => {
    idleTimeRef.current?.resetCounter();
  }, [idleTimeRef]);

  const onTimerFinish = useCallback(() => {
    replace("/");
  }, [replace]);

  useEffect(() => {
    enableSensor();
    return () => {
      enableSensor();
    };
  }, [enableSensor]);

  useEffect(() => {
    resetIdleTime();
    if (readErrorMessage) {
      const splited = readErrorMessage.split(":");
      if (splited[0] === "Err intr" && step === OfflineRechargeSteps.READ) {
        const message = (
          <div className="offline-recharce-success-message">
            <h3 className="offline-recharce-success-message-h3">
              <b>{splited[1]}</b>
            </h3>
            <div className="offline-recharce-success-message-div1">
              <p>Procure um caixa móvel</p>
              <p>
                <b>para ativar seu cartão/pulseira!</b>
              </p>
            </div>
            <p>
              <b>TOTEM SOMENTE PARA RECARGAS!</b>
            </p>
          </div>
        );
        setTimeout(() => {
          toastFullscreen(message, "warning", {
            icon: "error_outline",
            iconSize: 200,
            time: 3000,
          });
          setActiveLoad(null);
          if (step === OfflineRechargeSteps.READ) {
            replace("/");
          }
        }, 1000);
        clearCardData();
      } else if (
        splited[0] === "Err intr" &&
        step === OfflineRechargeSteps.RECORD
      ) {
        toastFullscreen(
          "Cartão/pulseira aproximado diferente do cartão/pulseira da recarga",
          "warning",
          { time: 3000 }
        );
        replace("/");
      } else {
        toastFullscreen(
          "Algo deu errado, se o erro persistir, procure o administrador",
          "warning",
          { time: 3000 }
        );
        replace("/");
      }
    }
  }, [
    readErrorMessage,
    toastFullscreen,
    clearCardData,
    toast,
    step,
    replace,
    resetIdleTime,
  ]);

  useEffect(() => {
    if (outOfDateError) {
      resetIdleTime();
      toast("Função indisponível", "error");
      clearCardData();
      replace("/");
    }
  }, [clearCardData, outOfDateError, replace, resetIdleTime, toast]);

  const addOrderLog = useCallback(
    async (
      order: IOrderLog | null,
      message: string,
      pedidoPOS?: IPedidoPosDTO,
      payment?: Payment,
      nfce?: IEmitedNfce[]
    ) => {
      try {
        if (order) {
          let orderLog = { ...order, payment, pedidoPOS, nfce };
          addLog("cashlessOffline", { message, orderLog });
          await orderRepositoryDB.add(orderLog, message);
        }
      } catch (error) {
        GetError(
          error,
          "addOrderLog",
          "useOfflineRecharge",
          "Falha ao adicionar log",
          ErrorCodeType.GENERAL
        );
      }
    },
    [addLog]
  );

  // Checagem de gravação pendente
  useEffect(() => {
    const checkPendingRecharge = async (associationId: string) => {
      const pendingRechargeOrder = await orderRepositoryDB.findPendingRecharge(
        associationId
      );
      if (
        pendingRechargeOrder &&
        pendingRechargeOrder.offlineRechargeStatus ===
          OfflineRechargeStatus.RECHARGE
      ) {
        try {
          const result = await cashlessRepository.getPendingRecharge({
            accountId: associationId,
            tag: pendingRechargeOrder?.customer?.tag ?? "",
            localId: kiosk?.localId,
          });
          addLog("cashlessOffline", {
            message: "Pending recharge",
            orderLog: { ...result, ...pendingRechargeOrder },
          });
          if (
            !pendingRechargeOrder.isSynchronized ||
            (result?.ordersId?.length &&
              result.ordersId.find((it) => it === pendingRechargeOrder.id))
          ) {
            setStep(OfflineRechargeSteps.RECORD);
            setRechargeORder(pendingRechargeOrder);
            cardToRecharge.current = {
              AssociationId: pendingRechargeOrder.customer?.associationId ?? "",
              Balance: pendingRechargeOrder.customer?.balance ?? 0,
              Document: pendingRechargeOrder.customer?.document ?? "",
              Tag: pendingRechargeOrder.customer?.tag ?? "",
            };
          } else {
            const orderLog = {
              id: pendingRechargeOrder.id,
              offlineRechargeStatus: IOfflineRechargeStatus.COMPLETED,
            } as IOrderLog;
            await addOrderLog(
              orderLog,
              `Gravação concluída em outro equipamento`
            );
          }
        } catch (error) {
          addLog("cashlessOffline", {
            message: "Falha ao obter recarga pendente",
            orderLog: pendingRechargeOrder,
          });
        }
      }
    };
    if (offlineCardData?.AssociationId && firstLoad.current) {
      resetIdleTime();
      checkPendingRecharge(offlineCardData.AssociationId);
    }
  }, [addLog, addOrderLog, kiosk, offlineCardData, resetIdleTime]);

  //Gravação do novo saldo no cartão
  const writeBalance = useCallback(
    async (order: Order) => {
      if (!!offlineCardData && cardToRecharge.current) {
        if (
          offlineCardData.AssociationId === cardToRecharge.current.AssociationId
        ) {
          await addOrderLog(
            order,
            `Gravação iniciada - Tag: ${order.customer?.tag}`
          );

          try {
            setActiveLoad("writing");
            await cefSharpCardReader.writeBalance(
              offlineCardData.Tag,
              offlineCardData.Balance + order.totalValue * 100
            );
            try {
              await syncOrderRepository.markAsRecorded(order.id);
            } catch (error) {
              addLog("cashlessOffline", {
                message: "Falha ao obter confirmar gravação",
                orderLog: error,
              });
            }
            const orderLog: IOrderLog = {
              ...order,
              offlineRechargeStatus: IOfflineRechargeStatus.COMPLETED,
              isDataRecorded: true,
            };
            await addOrderLog(orderLog, "Gravação concluida com sucesso");
            setTimeout(async () => {
              const message = (
                <div className="offline-recharce-success-message">
                  <h3>
                    <b>Recarga Concluída!</b>
                  </h3>
                  <p>Agora é só aproveita o evento</p>
                  <p>
                    <b>do seu jeito!</b>
                  </p>
                </div>
              );
              try {
                if (order.payment?.comprovanteTEF) {
                  printService.printString(order.payment.comprovanteTEF);
                }
              } catch (error) {
                await localforage.setItem(`@log-pos-errorScreen`, error);
              }
              printOfflineRecharge({
                newBalance: offlineCardData.Balance / 100 + order.totalValue,
                previousBalance: offlineCardData.Balance / 100,
                orderId: order.friendlyId,
                tag: offlineCardData.Tag,
                document: offlineCardData.Document,
              });
              toastFullscreen(message, "success", {
                icon: "task_alt",
                iconSize: 200,
                time: 3000,
              });
              replace("/");
              setActiveLoad(null);
            }, 3000);
          } catch (error) {
            await addOrderLog(
              order,
              `Falha ao gravar recarga no cartão: ${order.customer?.tag}`
            );
            setActiveLoad(null);
          }
        } else {
          toastFullscreen(
            "Cartão/pulseira aproximado diferente do cartão/pulseira da recarga",
            "warning",
            { time: 3000 }
          );
          await addOrderLog(order, `Cartão incorreto: ${offlineCardData.Tag}`);
          replace("/");
        }
      }
    },
    [
      addLog,
      addOrderLog,
      offlineCardData,
      printOfflineRecharge,
      replace,
      toastFullscreen,
    ]
  );

  useEffect(() => {
    // Checagem de leitura para gravação do cartão
    if (
      !!offlineCardData &&
      cardToRecharge.current &&
      step === OfflineRechargeSteps.RECORD &&
      rechargeORder &&
      !rechargeStarted.current
    ) {
      resetIdleTime();
      rechargeStarted.current = true;
      writeBalance(rechargeORder);
    }
  }, [offlineCardData, rechargeORder, resetIdleTime, step, writeBalance]);

  const createOfflineRechargeOrder = useCallback(
    (value: number, cardDataOffline: IFullCardData) => {
      if (getConfigurationProduct(ProdutoTipo.Recarga)) {
        const newOrderId = v4();
        const newOrder: Order = {
          isOfflineRecharge: true, // Recarga cashless offline
          isDataRecorded: false, // Pendente de gravação
          isOfflineRechargeRecordConfirmed: false, // Gravação concluída
          cartDate: new Date().toISOString(),
          id: newOrderId,
          orderItems: [
            {
              id: v4(),
              name: getConfigurationProduct(ProdutoTipo.Recarga)?.name ?? "",
              category: "",
              price: value,
              realPrice: value,
              totalPrice: value,
              description: null,
              productPrice: value,
              productId: getConfigurationProduct(ProdutoTipo.Recarga)?.id ?? "",
              productType: ProdutoTipo.Recarga,
              quantity: 1,
              imageUrl: "",
              hideCatalog: false,
              barcode: null,
              orderComposition: null,
              compositions: null,
              printerName: null,
            },
          ],
          totalValue: value,
          friendlyId:
            kiosk?.kioskName.replace(/\D/gm, "") +
            " " +
            newOrderId.substr(0, 4),
          createdAt: new Date().toISOString(),
          customer: {
            orderId: newOrderId,
            tag: cardDataOffline.Tag,
            number: cardDataOffline.Tag,
            orderPad: cardDataOffline.Tag,
            document: cardDataOffline.Document,
            balance: cardDataOffline.Balance,
            associationId: cardDataOffline.AssociationId,
          },
        };
        return newOrder;
      } else {
        toastFullscreen("Produtos de configuração não cadastrado", "error");
      }
    },
    [getConfigurationProduct, kiosk, toastFullscreen]
  );

  // Inicio do pagamento da recarga
  const paymentCashlessOffline = useCallback(
    async (type: TransactionType) => {
      if (moneyToFloat(rechargeValue) > 0 && offlineCardData) {
        setBlockReturn(true);
        const newOrder = createOfflineRechargeOrder(
          moneyToFloat(rechargeValue),
          offlineCardData
        );
        if (newOrder) {
          const orderLog: IOrderLog = {
            ...newOrder,
            offlineRechargeStatus: IOfflineRechargeStatus.PAYMENT,
          };
          await addOrderLog(
            orderLog,
            `Recarga offline: Tipo pgto: ${TransactionType[type]}, Tag: ${newOrder.customer?.tag}`
          );
          try {
            const result = await orderProvider.current?.startPayment(
              newOrder,
              true,
              type
            );
            if (result) {
              clearCardData();
              setStep(OfflineRechargeSteps.RECORD);
              setRechargeORder({
                ...result.order,
                payment: result.payment,
              });
              const orderLog: IOrderLog = {
                ...newOrder,
                offlineRechargeStatus: IOfflineRechargeStatus.RECHARGE,
              };
              await addOrderLog(
                orderLog,
                `Gravação pendente - Tag: ${newOrder.customer?.tag}`
              );
            } else {
              await addOrderLog(
                orderLog,
                `Gravação pendente - Tag: ${newOrder.customer?.tag}`
              );
              toast("Falha no pagamento", "error");
              GetError(
                new Error("Falha no pagamento"),
                "paymentCashlessOffline",
                "useOfflineRecharge",
                undefined,
                ErrorCodeType.PAYMENT
              );
            }
          } catch (error) {
            GetError(
              error,
              "paymentCashlessOffline",
              "useOfflineRecharge",
              undefined,
              ErrorCodeType.PAYMENT
            );
          } finally {
            setBlockReturn(false);
            resetIdleTime();
          }
        }
      } else {
        toast("Valor inválido", "warning");
      }
    },
    [
      addOrderLog,
      clearCardData,
      createOfflineRechargeOrder,
      offlineCardData,
      orderProvider,
      rechargeValue,
      resetIdleTime,
      toast,
    ]
  );

  useEffect(() => {
    if (!offlineCardData && firstLoad.current && cardDetected) {
      resetIdleTime();
      setActiveLoad("reading");
      setTimeout(() => {
        setActiveLoad(null);
      }, 3500);
    }
  }, [cardDetected, offlineCardData, resetIdleTime]);

  useEffect(() => {
    if (!!offlineCardData && firstLoad.current && !activeLoad) {
      resetIdleTime();
      cardToRecharge.current = offlineCardData;
      setStep(OfflineRechargeSteps.PAY_RECHARGE);
      firstLoad.current = false;
    }
  }, [offlineCardData, resetIdleTime, activeLoad]);

  const onpressback = useCallback(() => {
    setRechargeValue((prev) => prev.substring(0, prev.length - 1));
  }, []);
  const onpressKeyboard = useCallback((key: string) => {
    setRechargeValue((prev) => prev + key);
  }, []);
  const onPressClear = useCallback(() => {
    setRechargeValue("0.00");
  }, []);

  const onClickBack = useCallback(() => {
    cardToRecharge.current = null;
    firstLoad.current = true;
    clearCardData();

    if (step === OfflineRechargeSteps.PAY_RECHARGE) {
      setStep(OfflineRechargeSteps.READ);
    } else {
      navigation.replace("/");
    }
  }, [clearCardData, navigation, step]);

  return {
    onpressback,
    onpressKeyboard,
    onPressClear,
    paymentCashlessOffline,
    step,
    offlineCardData,
    rechargeValue,
    kiosk,
    onClickBack,
    activeLoad,
    blockReturn,
    onTimerFinish,
  };
};

export default UseOfflineRecharge;
